// imports MUST NOT use aliasing
import PersistentWebSocket from "./persistent-web-socket.js"

// code our errors for consistency
const errors = {
	// intialization errors
	"SCTKE.4.0.0": "Unhandled exception",
	"SCTKE.4.0.1": "Client not initialized. Please call scantek.init(voiCode) first",
	"SCTKE.4.0.2": "Malformed VoI Share Code",
	"SCTKE.4.0.3": "VoI Share code not set. Please call scantek.init(voiCode) first",
	
	
	// host DOM errors
	"SCTKE.4.1.0": "Couldn't find specified DOM element",
	
	// connectivity issues
	"SCTKE.4.2.0": "Unable to connect to webSocket service.",
}

const error = function(v) {
	if(v in errors) {
		this.code = v
		this.message = errors[v]
	} else {
		this.code = "SCTKE.4.0.0"
		this.message = errors['SCTK.4.0.0']
	}
	// console.debug(this.code, this.message)
	return this
}

const api = {
	voi: null,
	env: null,
	token: null,
	config: null,
	authRefreshTime: 15*60*1000,
	webSocket: new PersistentWebSocket("clientSDK"),
	refresh() {
		if(this.voi) {
			api.authorize(this.voi).then(()=>{
				console.warn("SCTK auth refreshed")
				// NOTE: this is being incorrectly flagged by snyk as CRW-94 unsanitized data from a remote source 
				// - https://app.snyk.io/org/nbamber/project/ce3bb9a6-51a5-47ce-ac12-823b9717649d#issue-a870e5ef-34f1-40b0-a842-3ee4b5706bb3
				setTimeout(api.refresh, api.authRefreshTime)
			}).catch(e=>{
				console.error(e)
				// NOTE: this is being incorrectly flagged by snyk as CRW-94 unsanitized data from a remote source 
				// - https://app.snyk.io/org/nbamber/project/ce3bb9a6-51a5-47ce-ac12-823b9717649d#issue-a870e5ef-34f1-40b0-a842-3ee4b5706bb3
				setTimeout(api.refresh, 5000)
			})
		}
	},
	set voiCode(code) { 
		let valid = true
		
		if(!code) valid = false
		else if(!code.match(/^([lbtdsp]-){0,1}[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}$/)) valid = false
		if(!valid){ throw new error("SCTKE.4.0.2") }
		
		// parse the code for env (if set)
		const a = code.split("-")
		let e = "";
			
		if(a.length>3) e = a.shift();
		let c = a.join("-");
		switch(e) {
			case "l": api.env = "localhost"; api.sdk = "localhost"; break;
			case "d": api.env = "dev.scantek.cloud"; api.sdk = "dev.sctk.au"; break;
			case "t": api.env = "test.scantek.cloud"; api.sdk = "test.sctk.au"; break;
			case "s": api.env = "sandbox.scantek.cloud"; api.sdk = "sandbox.sctk.au"; break;
			case "p": 
			default: api.env = "api.scantek.cloud"; api.sdk = "sctk.au"; break;
		}
		if(window.location.host.includes("sctk.au")) {
			const slug = window.location.host.split(".").shift()
			switch(slug) {
				case "dev": api.sdk = "dev.sctk.au"; break;
				case "test": api.sdk = "test.sctk.au"; break;
				case "sandbox": api.sdk = "sandbox.sctk.au"; break;
				case "demo": api.sdk = "sctk.au"; break;
				default: break;
			}
		} else if(window.location.host.includes("scantek.io")) {
			this.sdk = "dev.scantek.io"
		} else if(window.location.host.includes("demo.scantek.io")) {
			this.sdk = "dev.sctk.au"
		}
		console.debug(api.env, code)
	
		this.voi = code
	},
	get voiCode() { return this.voi },
	get urlSdk() { return `https://${this.sdk}/` },
	get urlBase() { return `http${this.env.includes("localhost")?"":"s"}://${this.env}/` },
	get urlWebSocket() { 
		const protocol = `ws${this.env.includes("localhost")?"":"s"}://`
		return `${protocol}ws.${this.env}/${api.config.apiMap.websocket||"wsv1"}?targetType=transaction&targetId=${api.config.targetTransaction}&authorizationToken=${api.token}`
	},
	
	loadTheme() {
		const url = `${this.urlBase}${api.config.apiMap.theme}/theme/${api.config.themeCode}`;
		return new Promise((accept, reject)=>{
			api.request(url, "GET").then(res=>{
				res.json().then(json=>{
					api.theme = json
					accept()
					console.debug(api.theme)
				})
			}).catch(reject)
		})
	},
	
	authorize(voiCode=null) {
		return new Promise((accept, reject)=>{
			if(voiCode != null) {  try { this.voiCode = voiCode } catch(e) { reject(e)  } }
			if(!this.voi || !this.env) reject(new error("SCTKE.4.0.3"))
			
			const url = `${this.urlBase}websdk/uv1/user/getAuthTokenForShareCode`
			api.request(url, "POST", { shareCode: api.voi }, false).then(async response=>{
				response.json().then(async json=>{
					api.token = json.token
					api.config = json.payload
					if(api.config.themeCode) {
						await api.loadTheme()
					}
					// TODO: connect to websocket for realtime comms
					// const wsUrl = (window.location.search.includes("localSocket"))?"wss:/integration.scantek.io/ws1":api.urlWebSocket
					// api.webSocket.connect(wsUrl)
					accept()
					setTimeout(()=>{
						api.refresh()
					}, api.authRefreshTime)
					console.warn("SCTK auth connected")
				}).catch(e=>{
					console.error("Failed to connect",e)
					reject(e)
				})
			}).catch(e=>{
				reject(e)
			})
			console.debug(url)
		})
	},

	request(url, method="POST", body=null, token=false) {
		method = method.toUpperCase();
		return new Promise(async (accept, reject)=>{
			token = api.token||false
			switch(method) {
				case "POST":case "GET":case "PUT":case "DELETE": break;
				default: return reject("Invalid method specified for api.request(url, method, body)");
			}
			let request = { method:method, headers: { 'Authorization': `Bearer ${token}` } }
			if(body != null) {
				request.body = JSON.stringify(body)
				request.headers["Content-Type"] = "application/json"
			}
			if(token===false) { delete(request.headers.Authorization) }
			fetch(url, request).then(async res=>{
				let errorObject
				if(res.status != 200) {
					let json = await res.json()
					if(json.errorCode) { 
						if(json.details&&json.details.errors) { console.debug(json.details.errors) }
						console.debug("Unable to authenticate Scantek client:", json.errorMessage, `[${json.errorCode}]`)
						errorObject = {
							code: json.errorCode,
							message: json.errorMessage
						}
					}
				}
				switch(res.status) {
					case 200: return accept(res); break;
					case 404: return reject(errorObject||`API Record not found`); break;
					case 400: case 401: case 402: case 403: return reject(errorObject||`Session authorization error `); break;
					case 500: case 501: case 502: return reject(errorObject||`Internal Server Error`); break;
					default: return reject(errorObject||`Unexpected response from server`);
				}
			}).catch(e=>{
				return reject(`Error communicating with server ${e.message}`);
			})
		})
	},
}

export default api
export { api, error }